const BaasUserForBaas1And15 = [
  'r.piepenbrock@douglas.nl',
  'm.szerlag@douglas.pl',
  'g.beccaria@douglas.it',
  'c.guarneri@douglas.it',
  't.cabeza@douglas.es',
  'ehoornaert@nocibe.fr',
  's.strassburger@douglas.de',
  'j.reckmann@douglas.de',
  'a.azhari@douglas.de',
  'f.fink@douglas.de',
  'l.ruepprich@douglas.de',
  'v.ahlert@douglas.de',
  'l.borggraefe@douglas.de',
  't.keller@douglas.de',
  'm.michels@douglas.de',
  'm.guelec@douglas.de',
  'k.koch@douglas.de',
  'm.keesen@douglas.de',
  'a.rickes@douglas.de',
  'j.koeller@douglas.de',
  'r.luttmannsberger@douglas.at',
  'm.szczepanik@douglas.pl',
  'c.vomhofe@douglas.de',
  'admin@douglas.dev',
  'm.gieron@douglas.de',
  'k.koessl@douglas.de',
  'p.zelle@douglas.de',
  'a.oguejiofor@douglas.de',
  'a.serralbo@ext.douglas.eu',
  'a.narvaez@ext.douglas.eu',
  'j.winkler@douglas.de',
  'k.medowa@douglas.de',
  'j.schaebsdau@douglas.de',
  't.weiler@douglas.de',
  'r.luttmannsberger@douglas.at',
  'm.jovanovic@douglas.nl',
  'w.grabek@douglas.pl',
  'c.guarneri@douglas.it',
  'an.cuesta@douglas.es',
  'jelouassini@nocibe.fr',
  'n.novak@douglas.de',
  'r.piepenbrock@douglas.nl',
  'h.winogrodzka@douglas.pl',
  'g.conti@douglas.it',
  't.cabeza@douglas.es',
  'mgilman@nocibe.fr',
  'x.rexhepi@douglas.de',
  'j.rogister@douglas.nl',
  'j.bulwarska@douglas.pl',
  's.russo@douglas.it',
  'h.benchelout@douglas.es',
  'edeplanque@nocibe.fr',
  's.roether@douglas.de',
  'a.wagenvoort@douglas.nl',
  'm.szczepanik@douglas.pl',
  'v.trieste@douglas.it',
  'r.vila@douglas.es',
  'dmartel@nocibe.fr',
  's.luetje@douglas.de',
  'b.eijsink@douglas.nl',
  'k.lysik@douglas.pl',
  'g.magistrali@douglas.it',
  'c.arizabaleta@douglas.es',
  'mforchantre@nocibe.fr',
  'no.burlager@douglas.de',
  'l.dejong@douglas.nl',
  'h.kos@douglas.pl',
  'g.beccaria@douglas.it',
  'sdelgove@nocibe.fr',
  'j.winkler@douglas.de',
  'j.timmermans@douglas.nl',
  'k.lipinska@douglas.pl',
  'prestataire.jkessler@nocibe.fr',
  'j.schaebsdau@douglas.de',
  'e.bons@douglas.nl',
  'e.pacia@ext.douglas.eu',
  'prestataire.alevesque@nocibe.fr',
  'k.medowa@douglas.de',
  'm.szerlag@douglas.pl',
  'ehoornaert@nocibe.fr',
  'ja.mueller@douglas.de',
  'ruslana.nedaszkiwska@heartandbrain.pl',
  'mcarre@nocibe.fr',
  't.weiler@douglas.de',
  'k.koch@douglas.de',
  'j.koeller@douglas.de',
  'm.elsner@douglas.de',
  'm.asmus@douglas.de',
  'l.mueller@douglas.de',
  'jul.mueller@douglas.de',
  'm.keesen@douglas.de',
  'k.bichler@douglas.at',
  'j.aerts@douglas.de',
  'a.schlaeger@douglas.de',
  'l.warthemann@douglas.de',
  'l.nowak@douglas.de',
  'm.stoppe@douglas.de',
  's.crnac@douglas.de',
  'b.beka@douglas.de',
  'we.lange@douglas.de',
  'j.aerts@douglas.de',
  'v.hlavackova@douglas.cz',
  'v.jelinkova@douglas.cz',
  'v.mandalikova@douglas.cz',
  'v.sumova@douglas.cz',
  'i.krejcova@douglas.cz',
  'j.hulova@douglas.cz',
  'm.lippenszky@douglas.hu',
  'd.wanca@douglas.cz',
  'm.vankempen@douglas.nl',
  'k.bichler@douglas.at',
  'h.benchelout@douglas.es',
  'n.szymczyk@douglas.de',
  'g.gijsberts@douglas.de',
  'j.rogister@douglas.nl',
  'b.eijsink@douglas.nl',
  'j.herres@douglas.de',
]

const localStorageKeyBaas15 = 'baas15active'

export const isBaas15Active = localStorage.getItem(localStorageKeyBaas15) !== 'false'

export const canSeeBaas15Toggle = email => BaasUserForBaas1And15.includes(email)

export const formatAsset = asset => {
  if (!isBaas15Active) {
    return asset
  }

  const newAsset = { ...asset }
  if (newAsset.width15) {
    newAsset.width = newAsset.width15
  }
  if (newAsset.height15) {
    newAsset.height = newAsset.height15
  }
  if (newAsset.minHeight15) {
    newAsset.minHeight = newAsset.minHeight15
  }
  if (newAsset.maxHeight15) {
    newAsset.maxHeight = newAsset.maxHeight15
  }

  if (newAsset.minHeight15 === newAsset.maxHeight15) {
    delete newAsset.minHeight
    delete newAsset.maxHeight
    delete newAsset.minHeight15
    delete newAsset.maxHeight15
  }
  delete newAsset.width15
  delete newAsset.height15

  return newAsset
}

export const formatValidation = validation => {
  if (!isBaas15Active) {
    return validation
  }

  const newValidation = { ...validation }
  if (newValidation.maxLength15) {
    newValidation.maxLength = newValidation.maxLength15
  }

  return newValidation
}

export const toggleBaaS15 = () => {
  const baas15LocalStorageValue = localStorage.getItem(localStorageKeyBaas15)
  const newLocalStorageValue = baas15LocalStorageValue === 'false' ? 'true' : 'false'
  localStorage.setItem(localStorageKeyBaas15, newLocalStorageValue)
}

export const getComponentname = metadata => {
  if (!isBaas15Active) {
    return metadata.name.toUpperCase()
  }
  let displayNameAndName = ''
  if (metadata.displayname) {
    displayNameAndName = metadata.displayname
    if (metadata.name.toUpperCase() !== metadata.displayname.toUpperCase()) {
      displayNameAndName += ' (' + metadata.name.toUpperCase() + ')'
    }
    return displayNameAndName
  }
  return metadata.name.toUpperCase()
}

export const hasConfigRestriction = config => {
  if (!config || !config.visibility) {
    return false
  }

  return (
    config.visibility.start !== '' ||
    config.visibility.end !== '' ||
    config.visibility.hideApp ||
    config.visibility.hideS ||
    config.visibility.hideM ||
    config.visibility.hideL
  )
}
