/* eslint-disable import/no-cycle */
import ListItemOpen from './ListItemOpen'
import ListItemClosed from './ListItemClosed'
import ListItemReady from './ListItemReady'
import ListItemReview from './ListItemReview'
import { isBaas15Active } from '../../../helpers/baasHelper'

export const getListItemComponent = status => {
  switch (status) {
    case 'review':
      return ListItemReview
    case 'ready':
      return ListItemReady
    case 'closed':
      return ListItemClosed
    default:
      return ListItemOpen
  }
}

export const deactivatedComponents = isBaas15Active
  ? [
      'i1',
      'i2',
      'i3',
      'i4',
      'i5',
      'h1',
      'h2',
      't1',
      't2',
      't4',
      't5',
      't6',
      't8',
      't9',
      't10',
      't11',
      'iwo1',
      'iwo2',
      'iwo3',
      'iwo4',
      'iwo5',
      'iwo6',
      'iwo7',
      'iwo8',
      'iwo9',
      'iwo10',
      'iwt1',
      'iwt2',
      'iwt3',
      'iwt4',
      'iwt5',
      'iwt6',
      'iwt7',
      'iwt8',
      'iwt9',
      'iwt10',
      'iwt11',
      'iwt12',
      'iwt13',
      'iwt14',
      'v1',
      'v2',
      'v3',
      'p1',
      'p2',
      'p3',
      'p4',
      'p5',
      'p6',
      'p7',
      'p8',
      'p9',
      'p10',
      'q2',
      'q3',
      'r1',
      's1',
      's2',
      's3',
      's5',
      's8',
      's11',
      'f1',
      'f1a',
      'f2',
      'f3',
      'f5',
      'button',
      'pr2',
      'pr3',
      'pr4',
      'pr5',
      'pop1',
      //'pop1new',
      'pop1video',
      'pop2',
      'pop3',
      'pop5',
      'pop6',
      'pop8',
      'pop10',
      'pop11',
      'f4',
      's4',
      'r2',
      'v4',
      'form',
      'spacer',
      'pxp1',
      'pdp2',
      'pdp3',
      'collapsible',
      'pr1',
    ]
  : ['p13', 'pop8new', 's12', 'tutorial', 'infographic', 'pop5new', 'pdp2new', 'pop1-baas15']
