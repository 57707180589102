import { useState, useEffect } from 'react'
import { errorCodes } from '@douglas/baas-shared'

let BACKEND_URL = ''
let CUSTOM_HEADER = {}

export const setAuthHeaders = authHeaders => {
  CUSTOM_HEADER = authHeaders
}

export const setBackendUrl = url => {
  BACKEND_URL = url
}

const errorCatcherThen = res => {
  if (!res.ok) {
    throw res
  }
  return res
}

const errorCatcherCatch = err => {
  if (err.status === 404 || err.status === 503) {
    window.hooks.setError(errorCodes.SERVER.UNREACHABLE)
  } else if (err.text) {
    err.text().then(errorMessage => window.hooks.setError(errorMessage))
  } else {
    window.hooks.setError(errorCodes.SERVER.OFFLINE)
  }
}

export const myFetch = async (endpoint, options, connectErrorCatcher = true) => {
  const request = fetch(BACKEND_URL + endpoint, {
    method: 'GET',
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...CUSTOM_HEADER,
    },
  })

  if (connectErrorCatcher) {
    request.then(errorCatcherThen).catch(errorCatcherCatch)
  }
  return request
}

export const myUpload = async (endpoint, formData) => {
  return fetch(BACKEND_URL + endpoint, {
    method: 'POST',
    body: formData,
    headers: {
      ...CUSTOM_HEADER,
    },
  })
    .then(errorCatcherThen)
    .catch(errorCatcherCatch)
}

export const myPost = async (endpoint, data, options, connectErrorCatcher = true) => {
  const request = fetch(BACKEND_URL + endpoint, {
    ...options,
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      ...CUSTOM_HEADER,
    },
  })

  if (connectErrorCatcher) {
    request.then(errorCatcherThen).catch(errorCatcherCatch)
  }
  return request
}

const useFetch = (endpoint, options, input = [], connectErrorCatcher = true) => {
  const [data, setData] = useState(undefined)
  const [status, setStatus] = useState(null)
  const [error, setError] = useState(null)

  // TODO: Add a better comment
  // We are checking if the method is GET and if no input is set, because we only want to default this loading value
  // for components that run useFetch on componentDidMount
  const [loading, setLoading] = useState(options.method === 'GET' && input ? true : false)

  const trigger = (data = null) => {
    return new Promise(async resolve => {
      setLoading(true)
      if (data !== null) {
        options = { ...options, body: JSON.stringify(data) }
      }
      const res = await myFetch(endpoint, { ...options }, connectErrorCatcher)
      setStatus(res.status)
      if (res.ok) {
        // Fetch doesnt support empty responses so this checks for that https://stackoverflow.com/a/51320025/5826761
        let data = await res.text()
        if (data) {
          try {
            data = JSON.parse(data)
          } catch (e) {
            console.log(e)
          }
        }

        setError(null)

        setData(data)
        resolve({ ...data, ok: res.ok })
      } else {
        if (connectErrorCatcher) {
          setError(true)
        } else {
          setError(await res.text())
        }
      }
      setLoading(false)
    })
  }

  if (input !== false) {
    useEffect(() => {
      trigger()
    }, input)
  }

  return { data, loading, trigger, status, error }
}

export const useGet = (endpoint, input = [], connectErrorCatcher = true) => {
  const { data, loading, trigger: refetch, status } = useFetch(endpoint, { method: 'GET' }, input, connectErrorCatcher)
  return { data, loading, refetch, status }
}

export const usePost = (endpoint, input = false, connectErrorCatcher = true) => {
  return useFetch(endpoint, { method: 'POST' }, input, connectErrorCatcher)
}

export const usePut = (endpoint, input = false, connectErrorCatcher = true) => {
  return useFetch(endpoint, { method: 'PUT' }, input, connectErrorCatcher)
}

export const useDelete = (endpoint, input = false, connectErrorCatcher = true) => {
  return useFetch(endpoint, { method: 'DELETE' }, input, connectErrorCatcher)
}

export const usePatch = (endpoint, input = false, connectErrorCatcher = true) => {
  return useFetch(endpoint, { method: 'PATCH' }, input, connectErrorCatcher)
}
