import React, { useEffect, useState } from 'react'
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import styled from 'styled-components'
import { Form } from 'semantic-ui-react'
import { getTextFromHtml } from '../../../helpers/getTextFromHtml'
import { Label } from './sharedComponents'
import { FormInlineError } from './FormInlineError'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const RichEditorStyles = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'Avenir Next', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 14px;
  padding: 15px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;

  .public-DraftStyleDefault-block {
    /* font-family: 'Avenir Next';*/
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $font-stack-zahrah !important;
  }

  h2 {
    font-size: 28px !important;
  }

  h3 {
    font-size: 20px !important;
  }

  h4 {
    font-size: 18px !important;
  }

  h5,
  h6 {
    font-size: 16px !important;
  }

  .RichEditor-editor {
    cursor: text;
    font-size: 16px;

    .public-DraftEditorPlaceholder-root,
    .public-DraftEditor-content {
      margin: 0 -15px -15px;
      padding: 0 15px 15px;
    }

    .public-DraftEditor-content {
      min-height: 100px;
    }
  }

  .RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    user-select: none;
  }

  .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
  }

  .RichEditor-activeButton {
    color: #5890ff;
  }

  .rdw-link-modal {
    min-width: 350px;
    font-family: $font-stack-avenir;
    height: auto;

    &-input {
      width: calc(100% - 30px) !important;
      margin-bottom: 20px !important;
    }

    &-label,
    &-button {
      font-family: $font-stack-avenir;
    }
  }

  .rdw-text-align-wrapper {
    display: none !important;
  }
`
const Wysi = props => {
  const {
    name,
    validation = {},
    errorMessage,
    label,
    register,
    unregister,
    setValue,
    errors,
    asText,
    getDefault,
    description,
    plainText,
    componentIndex = 0,
    componentName = 'form',
    originName = name,
  } = props
  /**
   * The default is a HTML string and thus we need to do some type conversions to get a working Editorstate.
   * We are not storing the paragraph tag that wraps our content because we wrap the HTML in our generator
   * already. Nesting paragraph tag's doesn't work as they will be misinterpreted by the browsers.
   **/
  const defaultValue = getDefault(name)
  const withParagraphTag = `<p>${defaultValue || ''}</p>`
    .replaceAll('<a href="/', '<a href="' + window.location.href + '/')
    .replaceAll('<a href="#', '<a href="' + window.location.href + '#')
  const fromHTML = convertFromHTML(withParagraphTag)
  const DraftState = ContentState.createFromBlockArray(fromHTML.contentBlocks, fromHTML.entityMap)

  /**
   * Here we check if the saved default has any text in it and if not we create a fresh
   * editorState instance. This is because `createWithContent` can't accept empty HTML.
   **/
  const defaultContent = DraftState.getPlainText() ? EditorState.createWithContent(DraftState) : EditorState.createEmpty()
  const [editorState, setEditorState] = useState(defaultContent)
  const [showCode, setShowCode] = useState(false)

  const clearupBaasDomains = rawContentState => {
    const baasDomains = [
      window.location.href,
      'https://baas.dglecom.net',
      'https://dev-baas.pub.az-we.dglecom.net',
      'https://prd-baas.pub.az-we.dglecom.net',
      'https://baas.p1.pub.dglecom.net',
      'https://baas.s1.pub.dglecom.net',
      'https://baas.d1.pub.dglecom.net',
      'https://briefing-as-a-service.d1.pub.dglecom.net',

      'https://baas.douglas.group',
    ]
    for (const [key] of Object.entries(rawContentState.entityMap)) {
      if (rawContentState.entityMap[key].type === 'LINK') {
        for (const domain of baasDomains) {
          if (rawContentState.entityMap[key].data.url) {
            rawContentState.entityMap[key].data.url = rawContentState.entityMap[key].data.url.replace(domain, '')
          }
          if (rawContentState.entityMap[key].data.href) {
            rawContentState.entityMap[key].data.href = rawContentState.entityMap[key].data.href.replace(domain, '')
          }
        }
      }
    }

    return rawContentState
  }

  useEffect(() => {
    let rawContentState = convertToRaw(editorState.getCurrentContent())

    rawContentState = clearupBaasDomains(rawContentState)

    const markup = draftToHtml(rawContentState)
    const getText = getTextFromHtml(markup)
    const newValue = plainText ? getText : markup //markupWithoutParagraph
    setValue(name, newValue.replaceAll('</a> .', '</a>.'))
  }, [editorState])

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 17) {
        setShowCode(!showCode)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    register(
      { name },
      {
        // Custom Validation because we store the HTML in our database but want to validate against plaintext
        validate: {
          maxLength: value => {
            if (!validation || !validation.maxLength || !value) return true
            return getTextFromHtml(value.trim()).length <= validation.maxLength
          },
          minLength: value => {
            if (!validation || !validation.minLength || !value) return true
            return getTextFromHtml(value.trim()).length >= validation.minLength
          },
          required: value => {
            if (!validation || !validation.required) return true
            if (!value) return false
            return Boolean(getTextFromHtml(value.trim()))
          },
        },
      }
    )
    setValue(name, defaultValue)
    return () => {
      unregister(name)
    }
  }, [])

  const onEditorStateChange = editorState => {
    setEditorState(editorState)
  }

  const toolbar = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'link'],
    inline: {
      inDropdown: false,
      options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
    },
  }

  return (
    <Form.Field
      error={!!errors[name]}
      id={`${componentIndex}-${componentName}-${originName}-text-wrapper`}
      style={{ position: 'relative' }}
    >
      <Label required={validation.required} text={label} description={description} />
      {!asText && (
        <RichEditorStyles>
          <div className="editor">
            <Editor
              toolbar={toolbar}
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={onEditorStateChange}
            />
            {showCode && <textarea disabled value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} />}
          </div>
        </RichEditorStyles>
      )}
      {asText && <span dangerouslySetInnerHTML={{ __html: defaultValue }} />}
      {!asText && errors[name] && <FormInlineError>{errorMessage}</FormInlineError>}
    </Form.Field>
  )
}

export default Wysi
