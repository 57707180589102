import React, { useContext, useRef } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { FEATURE } from '@douglas/baas-shared'
import Faq from './Faq'
import Guide from './Guide'
import ModuleEditor from './Briefings/ModuleEditor'
import ModuleView from './Briefings/ModuleEditor/View'
import BriefingsOverview from './Briefings/Overview'
import BriefingCreate from './Briefings/Create'
import BriefingEdit from './Briefings/Edit'
import BriefingShared from './Briefings/Share'
import Checkout from './Briefings/Checkout/Configure'
import Navbar from './Navbar'
import Review from './Briefings/Checkout/Review'
import Overlay from '../components/Overlay'
import Fork from './Briefings/Fork'
import CodeView from './Briefings/Code'
import RequestReviewOverlay from './Briefings/Checkout/Review/RequestReviewOverlay'
import PreviewView from './Briefings/Preview/View'
import Admin from './Admin'
import Users from './Admin/Users'
import Statistics from './Admin/Statistics'
import Copy from './Admin/Copy'
import Publish from './Admin/Publish'
import Products from './Admin/Products'
import Login from './Login'
import { UserContext } from '../contexts/User'
import { Access } from '../components/Access'
import Query from './Admin/Query'

function NotFound() {
  return <h1>Page not found</h1>
}

export default function Routes(props) {
  const { role } = useContext(UserContext)
  const { location } = props
  const previousRedirect = useRef('/')

  if (!role) {
    if (location.pathname === '/') return <Login {...props} />
    previousRedirect.current = location.pathname
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />
    )
  }

  if (previousRedirect.current !== '/') {
    const pathname = previousRedirect.current
    previousRedirect.current = '/'
    return (
      <Redirect
        to={{
          pathname,
          state: { from: location },
        }}
      />
    )
  }
  return (
    <div>
      {/* NAVBARS */}
      <Switch>
        <Route
          exact
          path="/briefings/:id([0-9a-fA-F]{24})/:mode(edit|modules|configure|review|success)?/:modifier(fork)?"
          render={props => <Navbar minimal={true} {...props} />}
        />
        <Route exact path="/briefings/:mode(new)" render={props => <Navbar minimal={true} {...props} />} />
        <Route component={Navbar} />
      </Switch>

      {/* CONTENT */}
      <Switch>
        <Route exact path="/faq" component={Faq} />
        <Route
          path="/guide/:category?"
          render={props => (
            <Access feature={FEATURE.guide}>
              <Guide {...props} />
            </Access>
          )}
        />
        <Route exact path="/" render={() => <Redirect to="/briefings/open" />} />
        <Route exact path="/briefings" render={() => <Redirect to="/briefings/open" />} />
        <Route
          exact
          path="/briefings/:category(open|review|rework|ready|closed)/:id?/:modifier(success|fork)?"
          render={props => (
            <Access feature={FEATURE.create}>
              <BriefingsOverview {...props} />
            </Access>
          )}
        />
        <Route
          exact
          path="/briefings/new"
          render={props => (
            <Access feature={FEATURE.create}>
              <BriefingCreate {...props} />
            </Access>
          )}
        />
        <Route
          path="/briefings/:id/edit"
          render={props => (
            <Access feature={FEATURE.create}>
              <BriefingEdit {...props} />
            </Access>
          )}
        />
        <Route
          path="/briefings/:id/modules"
          render={props => (
            <Access feature={FEATURE.create}>
              <ModuleEditor {...props} />
            </Access>
          )}
        />
        <Route
          path="/briefings/:id/configure"
          render={props => (
            <Access feature={FEATURE.create}>
              <Checkout step="configure" {...props} />
            </Access>
          )}
        />
        <Route
          path="/briefings/:id/review"
          render={props => (
            <Access feature={FEATURE.create}>
              <Review {...props} />
            </Access>
          )}
        />
        <Route
          exact
          path="/briefings/:id/share"
          render={props => (
            <Access feature={FEATURE.create} redirectGuestToPreview>
              <BriefingShared {...props} />
            </Access>
          )}
        />
        <Route exact path="/briefings/:id/view/modules" render={props => <ModuleView {...props} />} />
        <Route exact path="/briefings/:id/view/preview" render={props => <PreviewView {...props} />} />
        <Route exact path="/briefings/:id/view/code" render={props => <CodeView {...props} />} />
        <Route exact path="/briefings/:id/view" render={props => <Review view={true} {...props} />} />
        <Route exact path="/admin" component={Admin} />
        <Route exact path="/admin/users" component={Users} />
        <Route exact path="/admin/statistics" component={Statistics} />
        {/* <Route exact path="/admin/migrate" component={Migrate} /> */}
        <Route exact path="/admin/copy" component={Copy} />
        <Route exact path="/admin/publish" component={Publish} />
        <Route exact path="/admin/products" component={Products} />
        <Route exact path="/admin/query" component={Query} />
        <Route component={NotFound} />
      </Switch>

      {/* OVERLAYS */}
      <Route
        path="/briefings/:category(open|review|rework|ready|closed)/:id/fork"
        render={props => (
          <Overlay allowUserClose={true} name={`${props.match.params.id}/fork`} headline="Copy this briefing" {...props}>
            <Fork briefingId={props.match.params.id} {...props} />
          </Overlay>
        )}
      />
      <Route
        path="/briefings/:id/review/request-review"
        render={props => (
          <Overlay allowUserClose={true} name="request-review" headline="Request a briefing review." {...props}>
            <RequestReviewOverlay {...props} />
          </Overlay>
        )}
      />
    </div>
  )
}
