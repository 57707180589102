import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from 'semantic-ui-react'
import Layout from '../../Layout'
import { usePost } from '../../../hooks/restHooks'
import HeaderHr from '../../../components/semantic/HeaderHr'
import DelayedSpinner from '../../../components/semantic/DelayedSpinner'
import { deactivatedComponents } from '../../Briefings/Overview/helpers'

const Container = styled.div`
  max-width: 1337px;
  margin: 0 auto;

  h2 {
    font-size: 30px;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  td.locked {
    opacity: 0.4;
  }

  tr.locked td.edit {
    opacity: 1;
  }

  .hidden {
    visibility: hidden;
  }
`

export default function Query() {
  const [ids, setIds] = useState(
    '6659954cf5a716616540491e,66e41c6b962efb9e4968c7fa,6630fd9a14991077171751f3,66c5cfb57f96fd0a14c8f7a7,63eb808a612ae400409e407c,672a08a625498e1adfa77209'
  )
  const [loading, setLoading] = useState(false)
  const { trigger: queryBriefings } = usePost('admin/query')

  if (loading) {
    return <DelayedSpinner />
  }

  const handleIds = event => {
    setIds(event.target.value)
  }

  const handleSearch = async () => {
    setLoading(true)
    const res = await queryBriefings({
      ids,
      deactivatedComponents,
    })

    const link = document.createElement('a')
    link.href = 'data:text/csv,' + encodeURIComponent(res.csv)
    link.download = 'baas_export.csv'
    link.click()
    setLoading(false)
  }

  return (
    <Layout showHr={false} style={{ paddingBottom: '1rem' }}>
      <Container>
        <HeaderHr
          style={{ marginBottom: '1rem', marginTop: '1rem' }}
          title="Component information for briefing IDs."
          icon={'search'}
        />
        <textarea rows="20" cols="170" focus value={ids} onChange={handleIds} style={{ width: '500px' }}>
          {' '}
        </textarea>
        <br />
        <Button onClick={handleSearch}>Search</Button>
      </Container>
    </Layout>
  )
}
